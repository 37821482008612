import {DELETE, GET, POST, PUT} from './HTTP';
import { JourneyTab } from "@/models/JourneyTabModel";

export function getTab(id: string): Promise<JourneyTab>{
    return GET('/tabs/' + id);
}

export function updateTab(data: any): Promise<any> {
    return PUT(`/tabs/${data.id}`, data);
}

export function deleteTab(id: string): Promise<any> {
    return DELETE(`/tabs/${id}`);
}

export function createTabs(data: any): Promise<any> {
    return POST('/tabs', data);
}

export function updateTabTitles(data: any): Promise<any> {
    return PUT('/tabs', data);
}